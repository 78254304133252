<template>
  <CContainer class="text-black">
    <nav-bar v-bind:headerName="headerName"></nav-bar>
    <hr class="mt-0" />
    <div class="d-flex justify-content-between mt-4">
      <h6 class="mt-0 font-weight-bold">
        <CIcon class="mb-1" name="cil-basket"></CIcon>
        สินค้า
      </h6>
      <router-link to="/menu">
        <small class="text-description"> {{ cartTotal }} รายการ </small>
      </router-link>
    </div>
    <hr style="margin-top: 5px" />
    <table style="width: 100%" aria-describedby="">
      <th scope="col"></th>
      <tr
        v-for="list in cartLists"
        :key="list.productPLUId"
        style="border-bottom: 1px solid #e5e5e5"
      >
        <td style="width: 30%; padding: 5px">
          <div
            v-if="
              list.remoteImagePath === null ||
              list.remoteImagePath === undefined
            "
            class="square-box"
            :style="{
              'background-color': getImgColor(list.indexColor),
              width: '100%',
            }"
          ></div>
          <img
            v-else
            alt=""
            :src="list.remoteImagePath"
            class="img-fluid"
            style="border-radius: 5px"
            onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';"
          />
        </td>
        <td style="padding: 10px">
          <div class="d-flex justify-content-between">
            <h6 class="text-black">
              {{ list.productPLU.name }}
              <br />
              <small class="text-description">
                {{ list.note }}
              </small>
            </h6>

            <h6 class="font-weight-bold">฿{{ summary(list.totalAmount) }}</h6>
          </div>
          <div class="d-flex justify-content-between">
            <small class="text-description"> จำนวน: {{ list.quantity }} </small>
          </div>
        </td>
      </tr>
    </table>

    <div class="d-flex justify-content-between mt-4">
      <h6 class="mb-0 font-weight-bold">
        <CIcon class="mb-1" name="cil-truck"></CIcon> การจัดส่ง
      </h6>
    </div>
    <hr style="margin-top: 5px" />
    <ul class="list-group">
      <li class="list-group-item">
        <h6
          class="text-grey form-check-label d-flex justify-content-between"
          style="font-size: 12px"
        >
          <strong> รอรับสินค้าหน้าร้าน </strong>

          <small style="font-size: 10px" class="text-description">
            0.00 บาท
          </small>
        </h6>
        <small class="text-description">
          กรณีรอรับสินค้าหน้าร้าน จะไม่สามารถยกเลิกออเดอร์ได้
        </small>
      </li>
    </ul>

    <div class="d-flex justify-content-between mt-4">
      <h6 class="mb-0 font-weight-bold">
        <CIcon class="mb-1" name="cil-truck"></CIcon> ช่องทางการชำระเงิน
      </h6>
    </div>
    <hr style="margin-top: 5px" />
    <ul class="list-group">
      <li class="list-group-item">
        <h6
          class="text-grey form-check-label d-flex justify-content-between"
          style="font-size: 12px"
        >
          <strong> ช่องทางออนไลน์ </strong>

          <!--  <small style="font-size: 10px" class="text-description">
            <input type="radio" checked />
          </small> -->
        </h6>
        <small class="text-description">
          บัตรเครดิต/พร้อมเพย์/อินเตอร์เน็ตแบงก์กิ้ง
        </small>
      </li>
    </ul>

    <hr style="border: 1px solid grey" />

    <div class="d-flex justify-content-between text-grey">
      <h6>รวม</h6>
      <h6>฿{{ totalCost }}</h6>
    </div>
    <div class="d-flex justify-content-between text-grey">
      <h6>ค่าจัดส่ง</h6>
      <h6>฿0.00</h6>
    </div>
    <br />
    <div class="d-flex justify-content-between mt-3">
      <h5 class="font-weight-bold">รวมทั้งสิ้น</h5>
      <h5 class="font-weight-bold">฿{{ totalCost }}</h5>
    </div>

    <button class="mt-3 btn btn-block btn-success" @click="ecashierPayment()">
      <strong style="font-size: 12px"> ยืนยันคำสั่งซื้อ </strong>
    </button>
    <button class="btn btn-block btn-outline-dark mb-5" @click="cancelOrder()">
      <strong style="font-size: 12px"> ยกเลิกคำสั่งซื้อ </strong>
    </button>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '../menu/NavBar'
import payment from '@/services/payment'
import util from '@/util/util'
import moment from 'moment'
import '@/util/menu.css'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      cartLists: [],
      delivery: true,
      headerName: 'รายการสั่งซื้อ',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
    totalCost() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.totalAmount
      }, 0)

      sessionStorage.setItem('amount', total)
      return util.convertCurrency(total)
    },
    cartTotal() {
      let cart = JSON.parse(sessionStorage.getItem('cart'))
      if (cart) {
        return cart.length
      } else {
        return 0
      }
    },
  },
  created() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    if (this.cartLists.length === 0) {
      this.$router.push('/pickup/menulists')
    }
  },
  methods: {
    ...util,
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    addAddress(ads) {
      this.address = ads
    },
    goback() {
      window.history.back()
    },
    summary(amount) {
      return util.convertCurrency(amount)
    },
    goToPayment() {
      this.$router.push('/payment')
    },
    cancelOrder() {
      sessionStorage.clear()
      this.$router.push('/pickup/menulists')
    },
    ecashierPayment() {
      let merchantKey = 'D0qX9ScWnA0N9ZVOuPNXsVSCieRWclORmVYjyeIZ'
      let orderId = util.generateNumber()
      let urlcallback =
        process.env.VUE_APP_PAYMENT_GATEWAY_URL +
        '/callback/v1.0/' +
        orderId +
        '/webhook'

      console.log(urlcallback)

      let data = {
        sub: merchantKey,
        id: orderId,
        total: Number(this.totalCost),
        url: urlcallback,
        order_desc: this.shopName,
        subtotal: Number(this.totalCost / 1.07).toFixed(2),
        vat: Number((this.totalCost / 1.07) * 0.07).toFixed(2),
        vat_rate: '7.00',
        iat: new Date().getTime(),
      }

      payment({
        url: '/thaidotcom/payment/v1.0/ecashier',
        method: 'post',
        data: data,
      }).then((res) => {
        console.log(res.data.data)
        if (res.data.data) {
          window.location.replace(res.data.data)
        }
      })
    },
    generatePaymentLink() {
      let exp = moment().add(45, 'minutes').toISOString()

      let expdate = exp.replace(/\.[0-9]{2,3}/, '')

      let data = {
        service_id: '20994792017116125',
        product_name: 'ชำระเงินให้แก่ร้านค้า ' + this.shopName,
        sof: ['SOF_CC', 'SOF_RLP', 'SOF_PROMPTPAY'],
        amount: Number(this.totalCost),
        expire_date: expdate,
        description: 'มูลค่าการชำระ ' + this.totalCost + ' บาท',
        currency: 'THB',
        channel_type: 'WEBSITE',
        merchant_id: '2099479',
        request_channel: 'API',
        is_multi_use: true,
        email_list: ['imcroissants@gmail.com'],
        phone_list: ['0955977243'],
      }

      console.log(data)

      payment({
        url: '/mpay/payment/v1.0/' + this.uid + '/createlink',
        data: data,
        method: 'post',
      }).then((res) => {
        console.log(res.data)

        if (res.data.data.url !== undefined) {
          window.location.href = res.data.data.url
        } else {
          console.log(res)
          alert('เกิดข้อผิดพลาดบางอย่าง กรุณาติตต่อร้านค้า')
        }
      })
    },
  },
}
</script>
